<template>
  <div class="w-100 my-5">
    <div v-if="albumList">
      <div class="vertical-center" v-if="albumInfo">
        <span class="font-bold font-15 alpha-07">{{ albumInfo.name }}:</span>
        <span class="mx-5 font-bold font-20 color-blue">{{ albumInfo.photoList.length }}</span>
      </div>
      <div class="row my-5" v-if="albumInfo">
        <div :class="`col-sm${colSmSize}`" v-for="(photoUrl, index) in albumInfo.photoList" :key="(photoUrl, index)">
          <v-card class="my-2 p-0 box-white d-flex flex-column">
            <v-img style="cursor: zoom-in" height="300px" :src="photoUrl" @click="openImageViewer(index)" />
          </v-card>
        </div>
      </div>
      <div class="py-40 center" v-else>
        <span class="font-20 alpha-07">No album found!</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>Please wait ...</p>
      <b-spinner type="grow"></b-spinner>
    </div>
    <!-- VueEasyLightbox Component -->
    <div v-if="albumInfo && albumInfo.photoList">
      <VueEasyLightbox
        :visible="visibleImageViewer"
        :imgs="albumInfo.photoList"
        :index="imageIndex"
        @hide="visibleImageViewer = false"
        />
    </div>
    <!-- VueEasyLightbox Component end -->
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'AlbumDetail',
  components: {
    VueEasyLightbox
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    },
    albumList() {
      return this.$store.state.albumList;
    },
    albumInfo() {
      if (this.albumList) {
        return this.albumList.find(element => element.albumId === this.$route.params.albumId);
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      visibleImageViewer: false,
      imageIndex: 0
    }
  },
  methods: {
    openImageViewer(index) {
      this.imageIndex = index;
      this.visibleImageViewer = true;
    }
  }
};
</script>